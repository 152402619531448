import config from './../../config';
const URL_API = config.urlApi+'controlador/egreso/validateUploadEgresosCSV.php';

export default function validateUploadEgresosCSV({values}){
    // return $.ajax({
    //     url: URL_API,
    //     type: 'POST',
    //     data: values
    // })
    return fetch(URL_API, {
        method: 'POST',
        body: values,
    })
}