import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery'
import handleDownloadTemplate from '../../utils/downloadTemplateCSVEgresos'

export default function AddEgresosListPreparation() {
    const containerStyle = {
        margin: '0 auto',
    }

    const fieldGroupStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        marginBottom: '20px',
    }

    const fieldStyle = {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 10px',
        border: '1px solid #ccc',
        borderRadius: '20px',
        fontSize: '14px',
        backgroundColor: '#f8f9fa',
    }

    const renderField = (text, titlePopover, contentPopover) => (
        <div style={fieldStyle}>
            {text}
            <button
                className="btn btn-sm btn-light"
                data-toggle="popover"
                type="button"
                title={titlePopover}
                data-content={contentPopover}
                tabIndex="0"
                data-trigger="focus"
            >
                <FontAwesomeIcon icon={faCircleInfo} className="color-azul" />
            </button>
        </div>
    )

    useEffect(() => {
        $('[data-toggle="popover"]').popover({ html: true })
    }, [])

    return (
        <div style={containerStyle}>
            <h3 className="mb-3">Cómo preparar tu lista de egresos</h3>

            <p className="mb-4">
                Una lista de egresos es un archivo CSV (Excel) que contiene la información sobre tus egresos de una
                unidad en específico.
            </p>

            <div className="d-flex">
                <button className="btn btn-azul" onClick={handleDownloadTemplate}>
                    <FontAwesomeIcon icon={faFileDownload} className="mr-2" />
                    Descargar plantilla de archivo (.csv)
                </button>
            </div>

            <hr className="my-4" />

            <div className="mb-5">
                <h4 className="mb-2">Los siguientes campos son obligatorios:</h4>
                <div style={fieldGroupStyle}>
                    {renderField(
                        'Fecha del pago',
                        'fecha_del_pago',
                        'Fecha en la que se realizó el pago del egreso.<br>Tipo de dato: Fecha (DD/MM/AAAA).<br>Ejemplo: 03/10/2024',
                    )}
                    {renderField(
                        'Cantidad',
                        'cantidad',
                        'Monto del egreso sin símbolos ni comas, solo puntos decimales si es necesario.<br>Tipo de dato: Número con decimales.<br>Ejemplo: 2350.50',
                    )}
                    {renderField(
                        'Método de pago',
                        'metodo_pago',
                        'Método de pago utilizado para el egreso.<br>Debe coincidir exactamente con uno de los métodos de pago registrados en el sistema.<br>Tipo de dato: Texto.<br>Ejemplo: Efectivo',
                    )}
                    {renderField(
                        'Tipo de gasto',
                        'tipo_gasto',
                        'Tipo de gasto asociado al egreso.<br>Debe coincidir exactamente con el nombre de uno de los tipos de gasto registrados en el sistema.<br>Tipo de dato: Texto.<br>Ejemplo: Pago a proveedor',
                    )}
                    {renderField(
                        'Nombre de la unidad',
                        'nombre_unidad',
                        'Nombre de la unidad a la que pertenece el egreso.<br>Debe coincidir exactamente con el nombre de la unidad registrada en el sistema.<br>Tipo de dato: Texto.<br>Ejemplo: Administración',
                    )}
                </div>
            </div>

            <div className="mb-5">
                <h4 className="mb-2">Los siguientes campos son opcionales:</h4>
                <div style={fieldGroupStyle}>
                    {renderField(
                        'Nombre del empleado',
                        'nombre_empleado',
                        'Nombre del empleado asociado al egreso, como un préstamo.<br>Debe coincidir exactamente con el nombre del empleado registrado en el sistema.<br>Tipo de dato: Texto.<br>Ejemplo: Lorena Cruz Mondragón',
                    )}
                    {renderField(
                        'Descripción',
                        'descripcion',
                        'Descripción adicional del egreso.<br>Tipo de dato: Texto.<br>Ejemplo: Préstamo Lorena',
                    )}
                </div>
            </div>
        </div>
    )
}
