import { useState } from 'react'
import { Alert, AlertLoading, HideAlert } from '../utils/alerts'
import { ConnectionError, handleCallApiError } from '../validators/errors'
import validateUploadEgresosCSV from '../services/egreso/validateUploadEgresosCSV'
import confirmUploadEgresosCSV from '../services/egreso/confirmUploadEgresosCSV'

export default function useEgresos() {
    const idUsuario = localStorage.getItem('idUsuario')

    // Archivo CSV utilizado para la carga masiva
    const [fileCSV, setFileCSV] = useState(null)

    const validateUploadEgresosCSVService = async () => {
        try {
            if (!fileCSV) {
                throw new Error('No se ha seleccionado ningún archivo')
            }

            AlertLoading({ message: 'Subiendo archivo...' })
            let values = new FormData()
            values.append('archivo', fileCSV)
            values.append('id_usuario', idUsuario)
            // let json = Object.fromEntries(values)
            let response = await validateUploadEgresosCSV({ values: values })
            // let data = await response.text()
            
            if (!response.ok) {
                throw new Error('Error al subir el archivo')
            }
            
            let data = await response.json()
            if (data.response === 'error') {
                throw new Error(data.message)
            }
            HideAlert()

            return data
        } catch (e) {
            console.error('Error en uploadCSV => ', e.message)
            Alert({
                tipo: 'info',
                titulo: e.message,
                timer: 1500,
            })
            if (e instanceof ConnectionError) {
                handleCallApiError('ConnectionError')
            }
            return false
        }
    }

    const confirmUploadEgresosCSVService = async () => {
        try {
            AlertLoading({ message: 'Realizando cambios...' })
            let values = new FormData()
            values.append('archivo', fileCSV)
            values.append('id_usuario', idUsuario)
            let response = await confirmUploadEgresosCSV({ values: values })
            if (!response.ok) {
                throw new Error('Error al subir el archivo')
            }

            let data = await response.json()
            if (data.response === 'error') {
                throw new Error(data.message)
            }

            HideAlert()

            return data
        } catch (e) {
            console.error('Error en confirmUploadCSV => ', e.message)
            Alert({
                tipo: 'info',
                titulo: e.message,
                timer: 1500,
            })
            if (e instanceof ConnectionError) {
                handleCallApiError('ConnectionError')
            }
            return false
        }
    }

    return {
        fileCSV,
        setFileCSV,
        validateUploadEgresosCSVService,
        confirmUploadEgresosCSVService,
    }
}
