import { Link } from 'react-router-dom'
import React from 'react'
import Swal from 'sweetalert2'
// import changeEstatusPermisionarioService from './../../services/changeEstatusPermisionarioService';

export default function TableElementEgreso({ element, permisosUsuario }) {
    let {
        id_egreso,
        nombre_tipo_gasto,
        nombre_empleado,
        nombre_unidad,
        nombre_material,
        cantidad_disponible_material,
        cantidad,
        nombre_forma_pago,
        descripcion,
        fecha_del_pago,
        activo,
        nombre_producto_farmacia,
        cantidad_compra_producto,
        nombre_laboratorio,
        nombre_trabajo_laboratorio,
        nombre_paciente,
        folio_expediente,
        numero_recibo,
    } = element

    return (
        <tr key={id_egreso}>
            <th className="table-max-20">
                <div className="table__name">
                    {/* <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link to={`/ingresos/${id_egreso}`} className="dropdown-item" >Ver</Link>
                            {
                                (estadoEstatus === '1' ?
                                <a className="dropdown-item bg-danger text-white" onClick={() => onClickEstatus(false)}>Deshabilitar</a>
                                :
                                <a className="dropdown-item bg-success text-white" onClick={() => onClickEstatus(true)}>Habilitar</a>)
                            }
                        </div>
                    </div> */}

                    <div className="table__nameDescription">
                        <p>#{id_egreso}</p>
                        <small>{fecha_del_pago}</small>
                    </div>
                </div>
            </th>

            <td>
                <p>{nombre_unidad}</p>
            </td>

            <td>
                <small className="text-muted">{nombre_empleado && nombre_empleado}</small>
                <p>{nombre_tipo_gasto ? nombre_tipo_gasto : '-'}</p>
                <small className="text-muted">{nombre_unidad ? `Unidad: ${nombre_unidad}` : ''}</small>
            </td>

            <td>
                <p>$ {cantidad}</p>
                <small className="text-muted">{nombre_forma_pago}</small>
            </td>

            <td>
                {nombre_producto_farmacia && (
                    <>
                        <p>{`Producto: ${nombre_producto_farmacia}`}</p>
                        <small>{`Cantidad: ${cantidad_compra_producto}`}</small>
                    </>
                )}

                {nombre_laboratorio && (
                    <>
                        <p>{`${nombre_laboratorio}, ${nombre_trabajo_laboratorio}`}</p>
                        <small>{`Del paciente ${nombre_paciente} - Folio: ${folio_expediente}`}</small>
                    </>
                )}

                {descripcion && (
                    <>
                        <p>{descripcion}</p>
                    </>
                )}

                {!descripcion && nombre_material && (
                    <p>{`Material: ${nombre_material}, Cantidad: ${cantidad_disponible_material}`}</p>
                )}
            </td>
        </tr>
    )
}
