import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faPlusCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

export default function AddEgresosListResume({ data }) {

    console.log('data => ', data)

    const formatIds = ids => {
        if (!ids || ids.length === 0) {
            return 'No hay IDs'
        }
        return ids.join(', ')
    }

    const containerStyle = {
        '--color-negro': '#343434',
        '--color-rosa': '#D72A71',
        '--color-morado': '#79479C',
        '--color-azul': '#2E3A6C',
        margin: '0 auto',
        maxWidth: '800px',
        padding: '20px',
    }

    return (
        <div style={containerStyle}>
            <h3 className="mb-4">Resumen de datos procesados</h3>

            <div className="alert alert-info d-flex align-items-center" role="alert">
                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                <div>Esta información da un resumen de los egresos cargados en la lista CSV.</div>
            </div>

            <div className="card mb-4">
                <div className="card-body">
                    <h5 className="card-title">Totales</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            Total de egresos
                            <span className="badge rounded-pill">{data.total_filas}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span>
                                <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    className="mr-2"
                                    style={{ color: 'var(--color-verde)' }}
                                />
                                Total nuevos
                            </span>
                            <span className="badge rounded-pill">{data.total_nuevas}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span>
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    className="mr-2"
                                    style={{ color: 'var(--color-rosa)' }}
                                />
                                Total con errores
                            </span>
                            <span className="badge bg-danger text-white rounded-pill">{data.total_incompletas}</span>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Sección de IDs Nuevos
            {data.id_nuevos && data.id_nuevos.length > 0 && (
                <div className="card mb-4">
                    <div className="card-body">
                        <h5 className="card-title">
                            <FontAwesomeIcon
                                icon={faPlusCircle}
                                className="mr-2"
                                style={{ color: 'var(--color-verde)' }}
                            />
                            IDs nuevos
                        </h5>
                        <details className="my-3">
                            <summary>Ver IDs nuevos</summary>
                            <p className="card-text">{formatIds(data.id_nuevos)}</p>
                        </details>
                    </div>
                </div>
            )} */}

            {/* Sección de IDs Incompletos */}
            {data.id_incompletas && data.id_incompletas.length > 0 && (
                <div className="card mb-4">
                    <div className="card-body">
                        <h5 className="card-title">
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className="mr-2"
                                style={{ color: 'var(--color-rosa)' }}
                            />
                            Detalles de egresos con errores
                        </h5>
                        <details className="my-3">
                            <summary>Ver detalles</summary>
                            {data.id_incompletas && data.id_incompletas.length > 0 ? (
                                <ul className="list-group list-group-flush">
                                    {data.id_incompletas.map((item, index) => (
                                        <li key={index} className="list-group-item">
                                            <strong>Fila en el Excel:</strong> {item.id_egreso}
                                            <br />
                                            <strong>Error:</strong> {item.error}
                                            <br />
                                            <strong>Campos:</strong> {item.fields.join(', ')}
                                            <br />
                                            <strong>Descripción:</strong> {item.description}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="card-text">No hay egresos con datos incompletos</p>
                            )}
                        </details>
                    </div>
                </div>
            )}
        </div>
    )
}
