import React, { useState } from 'react'
import AddButton from '../../components/AddButton'
import CardCorteSemanal from '../../components/CardCorteSemanal'
import LoadingIndicator from '../../components/LoadingIndicator'
import Table from '../../components/Table'
import TableBody from '../../components/Table/TableBody'
import TableHead from '../../components/Table/TableHead'
import './Corte.css'
import AlertWarningSearch from '../../components/AlertWarningSearch'
import CardCorteSemanalEfectivo from '../../components/CardCorteSemanalEfectivo'
import CardCorteSemanalBanco from '../../components/CardCorteSemanalBanco'
import useCorte from '../../hooks/useCorte'
import ModalAddEgresosCSV from '../../components/ModalAddEgresosCSV'
import $ from 'jquery'

export default function Corte({ modulosPermisos }) {
    console.log('permisosUsuario => ', modulosPermisos)

    let {
        loadingIngresos,
        loadingEgresos,
        ingresos,
        ingresosTotales,
        egresos,
        fechaInicio,
        fechaFinal,
        setFechaInicio,
        setFechaFinal,
    } = useCorte()

    // Steps para el proceso de carga masiva de los productos en el modal
    const [steps, setSteps] = useState(null)

    function onSubmitFechas(e) {
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values)
        setFechaInicio(objectValues.fecha_inicial)
        setFechaFinal(objectValues.fecha_final)
    }

    return (
        <section>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                    <div className="d-flex align-items-center">
                        <h1>Corte semanal</h1>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-start justify-content-sm-start mt-md-4 mt-sm-4 mt-4">
                    <form onSubmit={onSubmitFechas} className="">
                        <div className={`d-flex justify-content-between align-items-center`}>
                            <input
                                id="fecha_inicial"
                                name="fecha_inicial"
                                className="input noMargin"
                                type="date"
                                defaultValue={fechaInicio}
                                required
                            />
                            <p className="mx-2">y</p>
                            <input
                                id="fecha_final"
                                name="fecha_final"
                                className="input noMargin"
                                type="date"
                                defaultValue={fechaFinal}
                                required
                            />
                            <button className="btn btn btn-gris ml-2">
                                <i className="fa-solid fa-search"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <CardCorteSemanalEfectivo
                total_ingresos_efectivo={ingresosTotales.total_ingresos_efectivo}
                total_gastos_efectivo={ingresosTotales.total_gastos_efectivo}
                total_efectivo_caja={ingresosTotales.total_efectivo_caja}
            />

            <CardCorteSemanalBanco
                total_ingresos_banco={ingresosTotales.total_ingresos_banco}
                total_gastos_banco={ingresosTotales.total_gastos_banco}
                total_banco={ingresosTotales.total_banco}
            />

            <CardCorteSemanal
                total_ingresos={ingresosTotales.total_ingresos}
                total_gastos={ingresosTotales.total_gastos}
                total={ingresosTotales.total}
            />

            <ModalAddEgresosCSV
                titulo={'Agregar egresos con Excel'}
                id={'ModalAddEgresosCSV'}
                steps={steps}
                setSteps={setSteps}
            />

            <div className="d-flex align-items-center my-5">
                <h2 className="">Lista de ingresos</h2>
            </div>

            <details className="my-3">
                <summary>
                    Ver ingresos
                    {Object.keys(ingresos).length !== 0 && !loadingIngresos && <> ({ingresos.length})</>}
                </summary>

                <LoadingIndicator show={loadingIngresos} />

                {Object.keys(ingresos).length !== 0 && !loadingIngresos && (
                    <Table>
                        <TableHead
                            rows={[
                                { id: 1, name: 'No. de recibo' },
                                { id: 2, name: 'Unidad' },
                                { id: 3, name: 'Proceso' },
                                { id: 4, name: 'Cantidad' },
                                { id: 5, name: 'Paciente' },
                            ]}
                        />
                        <TableBody elements={ingresos} typeElement="ingresos" permisosUsuario={modulosPermisos} />
                    </Table>
                )}

                {Object.keys(ingresos).length === 0 && !loadingIngresos && <AlertWarningSearch />}
            </details>

            <div className="d-flex align-items-center my-5">
                <h2 className="">Lista de egresos</h2>
                {modulosPermisos.egresos.escribir === '1' && (
                    <AddButton
                        text="Agregar egreso"
                        onClick={async () => {
                            setSteps(null)
                            $('#ModalAddEgresosCSV').modal('show')
                        }}
                    />
                )}
            </div>

            <details className="my-3">
                <summary>
                    Ver egresos
                    {Object.keys(egresos).length !== 0 && !loadingEgresos && <> ({egresos.length})</>}
                </summary>

                <LoadingIndicator show={loadingEgresos} />

                {Object.keys(egresos).length !== 0 && !loadingEgresos && (
                    <Table>
                        <TableHead
                            rows={[
                                { id: 1, name: 'No. de egreso' },
                                { id: 2, name: 'Unidad' },
                                { id: 3, name: 'Tipo de gasto' },
                                { id: 4, name: 'Cantidad' },
                                { id: 5, name: 'Descripción' },
                            ]}
                        />
                        <TableBody elements={egresos} typeElement="egresos" permisosUsuario={modulosPermisos} />
                    </Table>
                )}

                {Object.keys(egresos).length === 0 && !loadingEgresos && <AlertWarningSearch />}
            </details>
        </section>
    )
}
