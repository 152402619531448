import { useEffect, useState } from 'react'
import './style.css'
import $ from 'jquery'
import AddEgresosList from '../AddEgresosList'
import AddEgresosListPreparation from '../AddEgresosListPreparation'
import useEgresos from '../../hooks/useEgresos'
import AddEgresosListResume from '../AddEgresosListResume'
import AddEgresosListFinalResume from '../AddEgresosListFinalResume'

export default function ModalAddEgresosCSV({ titulo, id, steps, setSteps }) {
    const { fileCSV, setFileCSV, validateUploadEgresosCSVService, confirmUploadEgresosCSVService } = useEgresos()
    const [uploadData, setUploadData] = useState(null) // Estado para almacenar los datos recibidos
    const [finalResume, setFinalResume] = useState(null) // Estado para almacenar los datos recibidos
    const [error, setError] = useState(null)

    const onSubmitFileCSV = async e => {
        e.preventDefault()
        // Validate if the file is empty, then show an info sweetalert
        if (!fileCSV) {
            setError('No se ha seleccionado ningún archivo')
            return
        }

        let isUploaded = await validateUploadEgresosCSVService()
        if (isUploaded) {
            setUploadData(isUploaded.data) // Guardamos los datos recibidos en el estado
            setSteps(2) // Cambiamos el estado para mostrar el resumen
        } else {
            console.log('Error uploading file')
        }
    }

    const onConfirmUpload = async () => {
        let isConfirmed = await confirmUploadEgresosCSVService()
        if (isConfirmed) {
            console.log({ isConfirmed })
            console.log('Datos confirmados')
            setFinalResume(isConfirmed.data)
            setSteps(3)
            setFileCSV(null)
            setUploadData(null)
        } else {
            console.log('Error al confirmar datos')
        }
    }

    const onUploadCSV = () => {
        document.getElementById('AddEgresosCSVForm').requestSubmit()
    }

    useEffect(() => {
        $('[data-toggle="popover"]').popover()
    }, [])

    return (
        <div className="modal" id={id} tabIndex="-1" aria-hidden="true" role="dialog">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title">{titulo}</h2>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style={{ fontSize: 42 }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {(steps === null || steps < 1) && <AddEgresosListPreparation />}
                        {steps === 1 && (
                            <AddEgresosList
                                fileCSV={fileCSV}
                                setFileCSV={setFileCSV}
                                onSubmit={onSubmitFileCSV}
                                error={error}
                                setError={setError}
                            />
                        )}
                        {steps === 2 && (
                            <AddEgresosListResume data={uploadData} setFileCSV={setFileCSV} setSteps={setSteps} />
                        )}
                        {steps === 3 && (
                            <AddEgresosListFinalResume data={finalResume} />
                        )}
                    </div>
                    <div className="modal-footer">
                        {steps !== null && steps >= 1 && (
                            <button
                                type="button"
                                className="btn btn-gris btn-lg"
                                // data-dismiss="modal"
                                onClick={() => {
                                    if (steps !== null && steps > 1) setSteps(steps - 1)
                                    if (steps === 1) setSteps(null)
                                    if (steps === 2) setFileCSV(null)
                                    if (steps === 3) {
                                        setSteps(null)
                                        setFileCSV(null)
                                        setFinalResume(null)
                                    }
                                }}
                            >
                                Volver
                            </button>
                        )}
                        <button
                            type="button"
                            className="btn btn-azul btn-lg"
                            onClick={() => {
                                if (steps === null) setSteps(1)
                                if (steps === 1) onUploadCSV()
                                if (steps === 2) onConfirmUpload()
                                if (steps === 3) {
                                    setSteps(null)
                                    setFileCSV(null)
                                    setFinalResume(null)
                                }
                            }}
                            disabled={
                                steps === 2 && (uploadData.total_incompletas > 0 || uploadData.total_nuevas === 0)
                            }
                        >
                            {steps === null && 'Siguiente'}
                            {steps === 1 && 'Subir'}
                            {steps === 2 && 'Confirmar'}
                            {steps === 3 && 'Finalizar'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
