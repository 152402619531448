import { Link } from 'react-router-dom'
import React from 'react'
import Swal from 'sweetalert2'
// import changeEstatusPermisionarioService from './../../services/changeEstatusPermisionarioService';

export default function TableElementIngreso({ element, permisosUsuario }) {
    let {
        id_ingreso,
        folio,
        nombre_paciente,
        nombre_empleado,
        numero_recibo,
        nombre_proceso,
        cantidad,
        nombre_forma_pago,
        fecha_del_pago,
        activo,
        nombre_producto_farmacia,
        cantidad_salida_producto,
        nombre_unidad,
    } = element

    const [estadoEstatus, setEstadoEstatus] = React.useState(activo)

    const onClickEstatus = activo => {
        let msg = activo ? 'Quieres habilitar el ingreso?' : 'Quieres deshabilitar el ingreso?'
        let txt = activo ? 'Habilitar' : 'Deshabilitar'
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
            if (result.isConfirmed) {
                // let values = new FormData()
                // values.append('folio', folio)
                // values.append('activo', activo)
                // let objectValues = Object.fromEntries(values);
                // // console.log({objectValues})
                // changeEstatusPermisionarioService({values:objectValues}).then(response => {
                //     // console.log(response)
                //     let _respuesta = JSON.parse(response);
                //     if (_respuesta.response === "success") {
                //         setEstadoEstatus(activo)
                //         Swal.fire(
                //             'Estatus actualizado',
                //             '',
                //             'success'
                //         )
                //     }
                // })
            }
        })
    }

    return (
        <tr key={id_ingreso}>
            <th className="table-max-20">
                <div className="table__name">
                    {/* <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link to={`/ingresos/${id_ingreso}`} className="dropdown-item" >Ver</Link>
                            {
                                (estadoEstatus === '1' ?
                                <a className="dropdown-item bg-danger text-white" onClick={() => onClickEstatus(false)}>Deshabilitar</a>
                                :
                                <a className="dropdown-item bg-success text-white" onClick={() => onClickEstatus(true)}>Habilitar</a>)
                            }
                        </div>
                    </div> */}

                    <div className="table__nameDescription">
                        <p>{numero_recibo}</p>
                        <small>{fecha_del_pago}</small>
                    </div>
                </div>
            </th>

            <td>
                <p>{nombre_unidad}</p>
            </td>

            <td>
                <p>{nombre_proceso && nombre_proceso}</p>
                <p>{nombre_producto_farmacia && nombre_producto_farmacia}</p>
                <small className="text-muted">{!cantidad_salida_producto && nombre_empleado}</small>
                <small className="text-muted">
                    {cantidad_salida_producto && `Cantidad: ${cantidad_salida_producto}`}
                </small>
            </td>

            <td>
                <p>$ {cantidad}</p>
                <small className="text-muted">{nombre_forma_pago}</small>
            </td>

            <td>
                <p>{nombre_paciente}</p>
                <small className="text-muted">{folio}</small>
            </td>
        </tr>
    )
}
