const handleDownloadTemplate = () => {
    const headers = [
        'fecha_del_pago',
        'cantidad',
        'metodo_pago',
        'tipo_gasto',
        'nombre_unidad',
        'nombre_empleado',
        'descripcion',
    ]

    const sampleData = [
        [
            '2024-10-03', // fecha_del_pago
            '2350', // cantidad
            'Efectivo', // metodo_pago
            'Pago a proveedor', // tipo_gasto
            'Administración', // nombre_unidad
            '', // nombre_empleado
            'Pago a Jorge Vargas', // descripcion
        ],
        [
            '2024-10-02', // fecha_del_pago
            '100', // cantidad
            'Efectivo', // metodo_pago
            'Prestamo', // tipo_gasto
            'Administración', // nombre_unidad
            'Lorena Crúz Mondragón', // nombre_empleado
            'Préstamo Lorena', // descripcion
        ],
    ]

    // Incluir la BOM al inicio del contenido del CSV
    let csvContent = '\uFEFF'

    // Añadir encabezados
    csvContent += headers.join(',') + '\n'

    // Añadir datos de ejemplo
    sampleData.forEach(rowArray => {
        const row = rowArray
            .map(item => {
                // Reemplazar saltos de línea y retorno de carro por espacios
                const sanitizedItem = item.replace(/(\r\n|\n|\r)/gm, ' ').replace(/"/g, '""')
                return `"${sanitizedItem}"`
            })
            .join(',')
        csvContent += row + '\n'
    })

    // Crear un Blob con el contenido del CSV y la codificación correcta
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    // Crear un enlace para descargar el Blob
    const link = document.createElement('a')
    if (link.download !== undefined) {
        // Navegadores que soportan el atributo download
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', 'plantilla_egresos.csv')
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    } else {
        // Navegadores que no soportan el atributo download
        window.open(encodeURI(csvContent))
    }
}

export default handleDownloadTemplate
