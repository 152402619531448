import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faCloudUploadAlt, faFile } from '@fortawesome/free-solid-svg-icons'
import handleDownloadTemplate from '../../utils/downloadTemplateCSVEgresos'

export default function AddEgresosList({ fileCSV, setFileCSV, onSubmit, error, setError }) {
    const [isDragging, setIsDragging] = useState(false)
    const fileInputRef = useRef(null)

    const containerStyle = {
        margin: '0 auto',
    }

    const uploadAreaStyle = {
        border: `2px dashed ${isDragging ? '#007bff' : '#ccc'}`,
        borderRadius: '5px',
        padding: '20px',
        textAlign: 'center',
        color: '#6c757d',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
    }

    const handleDragOver = e => {
        e.preventDefault()
        setIsDragging(true)
    }

    const handleDragLeave = () => {
        setIsDragging(false)
    }

    const handleDrop = e => {
        e.preventDefault()
        setIsDragging(false)
        const droppedFile = e.dataTransfer.files[0]
        if (droppedFile) {
            validateFile(droppedFile)
        }
    }

    const handleFileSelect = e => {
        const selectedFile = e.target.files[0]
        if (selectedFile) {
            validateFile(selectedFile)
        }
    }

    const validateFile = file => {
        const validExtensions = ['csv', 'txt']
        const fileExtension = file.name.split('.').pop().toLowerCase()

        if (validExtensions.includes(fileExtension)) {
            setFileCSV(file)
            setError(null)
        } else {
            setFileCSV(null)
            setError('Formato de archivo no soportado. Solo se permiten archivos CSV.')
        }
    }

    const handleUploadClick = () => {
        fileInputRef.current.click()
    }

    return (
        <div style={containerStyle}>
            <h3 className="mb-4">Agregar lista de egresos</h3>

            <p className="mb-4">
                Antes de subir tu lista, asegúrate de que tienes los campos obligatorios con el formato correcto. La
                lista debe estar en formato CSV.
            </p>

            <button className="btn btn-azul" onClick={handleDownloadTemplate}>
                <FontAwesomeIcon icon={faFileDownload} className="mr-2" />
                Descargar plantilla de archivo (.csv)
            </button>

            <hr className="my-4" />

            <div className="mt-4">
                <label className="font-weight-bold">Subir lista</label>
                <small className="text-dark d-block mb-3">Los formatos soportados son CSV y TXT</small>
            </div>

            <div
                style={uploadAreaStyle}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={handleUploadClick}
            >
                {fileCSV ? (
                    <>
                        <FontAwesomeIcon icon={faFile} size="3x" className="mb-3" />
                        <p>{fileCSV.name}</p>
                    </>
                ) : (
                    <>
                        <FontAwesomeIcon icon={faCloudUploadAlt} size="3x" className="mb-3" />
                        <p>Arrastra y suelta el archivo aquí o haz clic en</p>
                    </>
                )}
            </div>
            <form id="AddEgresosCSVForm" onSubmit={onSubmit}>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    style={{ display: 'none' }}
                    accept=".csv,.txt"
                    name="file"
                />
            </form>
            {error && <p className="text-danger mt-3">{error}</p>}
        </div>
    )
}
