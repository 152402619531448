import { useEffect, useState } from 'react'
import $ from 'jquery'
import { ConnectionError, handleCallApiError } from '../validators/errors'
import getIngresosTotales from '../services/ingreso/getIngresosTotales'
import getIngresos from '../services/ingreso/getIngresos'
import getEgresos from '../services/egreso/getEgresos'

export default function useCorte() {
    // const idUsuario = localStorage.getItem('idUsuario')
    var curr = new Date()
    var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay() - 1)))
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7))

    const [loadingIngresos, setLoadingIngresos] = useState(true)
    const [loadingEgresos, setLoadingEgresos] = useState(true)
    const [ingresos, setIngresos] = useState([])
    const [ingresosTotales, setIngresosTotales] = useState({})
    const [egresos, setEgresos] = useState([])
    const [fechaInicio, setFechaInicio] = useState(firstday.toISOString().split('T')[0])
    const [fechaFinal, setFechaFinal] = useState(lastday.toISOString().split('T')[0])

    function getIngresosTotalesService() {
        setLoadingIngresos(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
        }
        // // console.log(firstday, lastday)
        getIngresosTotales({ values: data })
            .then(res => {
                // console.log(res)
                let _res = JSON.parse(res)
                console.log(_res)
                if (_res.response === 'success') {
                    setIngresosTotales(_res.data)
                }
                setLoadingIngresos(false)
            })
            .catch(e => {
                if (e instanceof ConnectionError) {
                    handleCallApiError('ConnectionError')
                }
            })
    }

    function getIngresosService() {
        setLoadingIngresos(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
        }

        // // console.log(firstday, lastday)
        getIngresos({ values: data })
            .then(res => {
                // console.log(res)
                let _res = JSON.parse(res)
                // console.log(_res)
                if (_res.response === 'success') {
                    setIngresos(_res.data)
                }
                setLoadingIngresos(false)
            })
            .catch(e => {
                if (e instanceof ConnectionError) {
                    handleCallApiError('ConnectionError')
                }
            })
    }

    function getEgresosService() {
        setLoadingEgresos(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
        }
        // // console.log(firstday, lastday)
        getEgresos({ values: data })
            .then(res => {
                // console.log(res)
                let _res = JSON.parse(res)
                // console.log(_res)
                if (_res.response === 'success') {
                    setEgresos(_res.data)
                }
                setLoadingEgresos(false)
            })
            .catch(e => {
                if (e instanceof ConnectionError) {
                    handleCallApiError('ConnectionError')
                }
            })
    }

    useEffect(() => {
        getIngresosService()
        getIngresosTotalesService()
        getEgresosService()
        // console.log(fechaInicio, fechaFinal)
        $('#fecha_inicial').val(fechaInicio)
    }, [fechaInicio, fechaFinal])

    return {
        loadingIngresos,
        loadingEgresos,
        ingresos,
        ingresosTotales,
        egresos,
        fechaInicio,
        fechaFinal,
        setFechaInicio,
        setFechaFinal,
        getIngresosService,
        getIngresosTotalesService,
        getEgresosService,
    }
}
